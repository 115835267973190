import { ChartDataXY, ChartSeriesXY } from '../data/chart-data-types';
import { customElement, property } from 'lit/decorators.js';
import { DashboardWidget } from './dashboard-widget';
import { DateRange } from './dashboard-filter';
import { generatedQuotesCache } from '../data/generated-quotes-cache';
import { getBlankRevenueForDates } from './generated-purchase-orders-widget';
import { html, PropertyValues } from 'lit';
import {
  localDateTimeToServer,
  serverDateTimeToLocalDateTime
} from '../../../../webmodule-common/other/datetime-converter';
import { NullPromise, Snippet } from '../../../../webmodule-common/interop/webmodule-interop';
import { TimelineWidgetModal } from './timeline-widget-modal';
import { tlang } from '../../../../webmodule-common/other/language/lang';
import { ViewDailyRevenue } from '../../../api/supplier-api-interface-franchiseenetwork';

const ApexCharts = (globalThis as any).ApexCharts;

@customElement('generated-quotes-revenue-widget')
export class GeneratedQuotesWidget extends DashboardWidget {
  chart: any | null = null;

  @property() dateRange?: DateRange;
  @property() branchIds?: string[] = [];
  @property() userId?: string;

  @property()
  private _cache = generatedQuotesCache();

  protected async refreshData(): NullPromise<ViewDailyRevenue[]> {
    if (this.dateRange) {
      const startDate = localDateTimeToServer(this.dateRange.startDate);
      const endDate = localDateTimeToServer(this.dateRange.endDate);
      if (this.branchIds) {
        const result = await this._cache?.getData(startDate, endDate, this.branchIds, this.userId);
        if (result) {
          return result.revenueSummary;
        }
      } else {
        return getBlankRevenueForDates(startDate, endDate);
      }
    }
    return null;
  }

  protected async firstUpdated(): Promise<void> {
    const options = {
      chart: {
        type: 'area',
        height: '100%',
        selection: {
          enabled: false
        },
        toolbar: {
          tools: {
            download: false,
            customIcons: [
              {
                icon: '<i class="fa fa-plus-circle fa-lg"></i>',
                index: 0,
                title: 'Zoom',
                class: 'custom-icon',
                click: (chart: any, options: any, e: any) => this.onChartClick(e, chart, options)
              }
            ]
          }
        },
        zoom: {
          enabled: false
        }
      },
      stroke: {
        width: 1
      },
      dataLabels: {
        enabled: false
      },
      legend: { show: false },
      tooltip: {
        enabled: false
      },
      states: {
        hover: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        active: {
          filter: {
            type: 'none',
            value: 0
          }
        }
      },
      series: [],
      xaxis: {
        type: 'datetime'
      },
      yaxis: {
        labels: {
          formatter: (value: number) => this.getYAxisLabel(value)
        }
      }
    };

    this.chart = new ApexCharts(this.querySelector('.quote-issued-revenue'), options);
    this.chart.render();
  }

  protected updated(changedProperties: PropertyValues) {
    super.updated(changedProperties);
    this.updateData();
  }

  private getTitle(): string {
    return tlang`!!quote!! Issued - ${this.dateRange?.label}`;
  }

  protected getHeader(): Snippet {
    return this.getTitle();
  }

  protected getBody(): Snippet {
    return html`<div class="quote-issued-revenue"></div>`;
  }

  protected getYAxisLabel(value: number): string {
    return `$${Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    }).format(value)}`;
  }

  protected async onChartClick(_event: any, _chartContext: any, config: any) {
    const modal = new TimelineWidgetModal(this.getTitle(), config.config.series);
    await modal.showModal();
  }

  protected updateData(): void {
    this.refreshData().then(results => {
      if (results) {
        const formatDate = (s: string) => {
          let dt = serverDateTimeToLocalDateTime(s);
          dt = dt.set({ hour: 0, minute: 0 });
          return dt.toMillis();
        };

        const chartData: ChartDataXY[] = [];
        results.map(r =>
          chartData.push({
            x: formatDate(r.date),
            y: r.dailyRevenue
          })
        );

        const chartSeries: ChartSeriesXY[] = [];
        chartSeries.push({
          data: chartData,
          name: tlang`%%quote%% Revenue`
        });
        this.chart.updateSeries(chartSeries, true);
      }
    });
  }
}
