import { ActiveLicensesWidget } from './dashboard/widget/active-licenses-widget';
import { DashboardFilter } from './dashboard/widget/dashboard-filter';
import { DeliveryPipelinePoWidget } from './dashboard/widget/delivery-pipeline-purchaseorder-widget';
import { GeneratedPurchaseOrdersWidget } from './dashboard/widget/generated-purchase-orders-widget';
import { GeneratedQuotesWidget } from './dashboard/widget/generated-quotes-widget';
import { SalesSuccessWidget } from './dashboard/widget/sales-success-widget';
import { TerritoryFranchiseeTableElement } from '../settings/ui/territory-franchisee-table-element';
import { WebModuleAssignUserEdit } from '../dealer/view/assignto-edit';

export const supplierComponentRegistry = {
  dashboardFilterControls: DashboardFilter,
  dashboardDeliveryPipelinePoWidget: DeliveryPipelinePoWidget,
  dashboardActiveLicensesWidget: ActiveLicensesWidget,
  dashboardSalesSuccessWidget: SalesSuccessWidget,
  dashboardGeneratedPurchaseOrdersWidgets: GeneratedPurchaseOrdersWidget,
  dashboardGeneratedQuotesWidget: GeneratedQuotesWidget,
  webModuleAssignUserEdit: WebModuleAssignUserEdit,
  territoryFranchiseeTableElement: TerritoryFranchiseeTableElement
};
